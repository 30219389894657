import React, { useState } from "react"
import { Navbar } from "react-bootstrap"
import { Link } from "gatsby"
import ProfilePic from './profilePic'
export default props => {
  const [open, setOpen] = useState(false);

  return (
    <Navbar
      className=" navbar-dark bg-primary fixed-top"
      id="sideNav"
    >

      <ProfilePic />
                 
      <button
        className="navbar-toggler"
        type="button"
        onClick={_ => setOpen(!open)}
        aria-controls="navbarSupportedContent"
        aria-expanded={open}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>X
      </button>

      <Navbar.Collapse id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link js-scroll-trigger" to="/">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link js-scroll-trigger" to="/blog">
              Blog
            </Link>
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  )
}
