import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default props => {
  const { file: imgSrc, site } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "profilo-cropped.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          personalData {
            name
            surname
          }
        }
      }
    }
  `);

  return (
    <>
      <span className="d-block d-lg-none">
        {site.siteMetadata.personalData.name}
        {site.siteMetadata.personalData.surname}
      </span>
      <span className="d-none d-lg-block" style={{width:'80%'}}>
        <Img
          className="img-fluid img-profile rounded-circle mx-auto mb-2"
          fluid={imgSrc.childImageSharp.fluid}
        />
      </span>
    </>
  )
}
